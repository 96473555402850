<template>
  <div class="px-4 pt-10">
    <van-cell-group class="mb-10">
      <van-field
        label="卡号"
        placeholder="请输入卡号"
        v-model="cardNumber"
        type="tel"
      />
      <van-field label="姓名" placeholder="请输入姓名" v-model="name" />
      <van-field
        label="手机号"
        placeholder="请输入手机号"
        v-model="mobile"
        type="tel"
        maxlength="11"
      />
    </van-cell-group>
    <van-button @click="submit" type="primary" block :disabled="disabled"
      >注册</van-button
    >
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, Field, CellGroup, Dialog } from 'vant'

Vue.use(Button)
Vue.use(Field)
Vue.use(CellGroup)
export default {
  data() {
    return {
      cardNumber: '',
      name: '',
      mobile: ''
    }
  },

  methods: {
    async submit() {
      await Dialog({
        message: '注册成功！'
      })
      this.$router.go(-1)
    }
  },

  computed: {
    disabled() {
      return this.mobile.length < 11 || !this.cardNumber || !this.name
    }
  }
}
</script>
<style lang="scss" scoped>
</style>